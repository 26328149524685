import { createStore } from 'vuex'

export default createStore({
  state: {
    animal: null
  },
  getters: {
    getAnimal(state) {
      return state.animal
    }
  },
  mutations: {
    setAnimal(state, animal) {
      state.animal = animal
    }
  },
  actions: {
  },
  modules: {
  }
})
