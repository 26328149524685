<template>
  <div class="container">
    <div class="row align-items-start">
      <div class="col text-start">
        <b>Nome:</b>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        {{ animal.frigorifico.nome ? animal.frigorifico.nome : "-" }}
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        <b>Peso Banda 1:</b>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        {{
          animal.frigorifico.pesoBanda1 ? animal.frigorifico.pesoBanda1 : "-"
        }}
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        <b>Peso Banda 2:</b>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        {{
          animal.frigorifico.pesoBanda2 ? animal.frigorifico.pesoBanda2 : "-"
        }}
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        <b>Porcentagem de carcaça:</b>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        {{
          animal.frigorifico.porcentagemCarcaca
            ? animal.frigorifico.porcentagemCarcaca
            : "-"
        }}
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        <b>Maturidade:</b>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        {{
          animal.frigorifico.maturidade ? animal.frigorifico.maturidade : "-"
        }}
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        <b>Acabamento:</b>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        {{
          animal.frigorifico.acabamento ? animal.frigorifico.acabamento : "-"
        }}
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        <b>Data do Abate:</b>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        {{ formataData(animal.frigorifico.dtMorte) }}
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        <b>Observação 1:</b>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        {{ animal.frigorifico.obs1 ? animal.frigorifico.obs1 : "-" }}
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        <b>Observação 2:</b>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col text-start">
        {{ animal.frigorifico.obs2 ? animal.frigorifico.obs2 : "-" }}
      </div>
    </div>
  </div>
</template>

<script>
import { mocked } from "@/store/mocked-animals.js";

let vm;

export default {
  name: "Frigorifico",
  components: {},
  async created() {
    vm = this;
    if (!vm.$route.params || !vm.$route.params.id)
      return vm.$router.push({ name: "Index" });

    if (vm.$store.getters.getAnimal == null) {
      let response = null;
      try {
        const key = `${vm.$route.params.id}`;
        const cached = mocked[key];

        response = cached
          ? { data: cached }
          : await vm.$http.get("/animal-aberto/" + vm.$route.params.id);

        if (response.data.error) throw response.data.message;
      } catch (e) {
        return vm.$router.push({ name: "Index" });
      }

      vm.$store.commit("setAnimal", response.data);
      vm.$emitter.emit("setAnimal", vm.$store.getters.getAnimal);
    }

    vm.animal = vm.$store.getters.getAnimal;
  },
  data() {
    return {
      animal: {},
    };
  },
  methods: {
    formataData(data) {
      if (!data || data.length < 10) return "-";
      return vm.$dayjs(data).format("DD/MM/YYYY");
    },
  },
};
</script>
