<template>
  <div style="width: 90%; margin: 0 auto">
    <img alt="SafeBeef" src="./assets/logo.png" class="mb-4 mt-4 mw-100" />
    <div class="card" id="principal">
      <h5 class="card-header bg-light text-dark">
        <img alt="Animal" src="./assets/cow.png" class="cow" /><b>
          Detalhes do animal</b
        >
      </h5>
      <div class="card-body d-flex text-white bg-dark">
        <div class="container">
          <div class="row">
            <div
              class="d-flex flex-column col text-start justify-content-center"
            >
              <div>
                <b>ID do Animal: </b>{{ animal.idAnimalFazenda || "-" }}
              </div>
              <div>
                <b>Raça: </b
                >{{
                  animal.composicaoRacial
                    ? animal.composicaoRacial.find(
                        (r) => r.racaPrincipal == true
                      ).nome
                    : "-"
                }}
              </div>
              <div><b>Sexo: </b>{{ animal.sexo || "-" }}</div>
              <div>
                <b>Fazenda: </b
                >{{
                  animal.localizacaoAtual
                    ? animal.localizacaoAtual.fazenda.nome.replace(
                        "Vale do Sol",
                        "Fazenda União do Brasil"
                      )
                    : "-"
                }}
              </div>
              <div>
                <b>Idade: </b
                >{{
                  animal.dataNascimento ? getDataNascimento() + " meses" : "-"
                }}
              </div>
            </div>

            <div class="col col-md-auto text-end">
              <img class="mt-3 mb-3" :src="getURLSelo()" height="150" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" style="margin-top: 20px">
      <div class="card-body">
        <div id="nav">
          <router-link :to="'/animal/' + idAnimalBlockchain"
            >Informações</router-link
          >
          |
          <router-link :to="'/animal/' + idAnimalBlockchain + '/pesagem'"
            >Pesagem</router-link
          >
          |
          <router-link :to="'/animal/' + idAnimalBlockchain + '/nutricao'"
            >Nutrição</router-link
          >
          |
          <router-link :to="'/animal/' + idAnimalBlockchain + '/medicamentos'"
            >Medicamentos</router-link
          >
          |
          <router-link :to="'/animal/' + idAnimalBlockchain + '/exames'"
            >Exames</router-link
          >
          |
          <router-link :to="'/animal/' + idAnimalBlockchain + '/reproducoes'"
            >Reproduções</router-link
          >
          |
          <router-link :to="'/animal/' + idAnimalBlockchain + '/localizacoes'"
            >Localizações</router-link
          >
          |
          <router-link :to="'/animal/' + idAnimalBlockchain + '/lotes'"
            >Lotes</router-link
          >
          |
          <router-link
            :to="'/animal/' + idAnimalBlockchain + '/acoes-de-manejo'"
            >Ações de manejo</router-link
          >
          |
          <router-link :to="'/animal/' + idAnimalBlockchain + '/vendas'"
            >Vendas</router-link
          >
          |
          <router-link :to="'/animal/' + idAnimalBlockchain + '/frigorifico'"
            >Frigorífico</router-link
          >
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
let vm;

export default {
  name: "app",
  components: {},
  async created() {
    vm = this;
    vm.$emitter.on("setAnimal", vm.setAnimal);
  },
  data() {
    return {
      idAnimalBlockchain: null,
      animal: {},
    };
  },
  methods: {
    setAnimal(animal) {
      vm.idAnimalBlockchain = animal.idBlockchain;
      vm.animal = animal;
    },
    getDataNascimento() {
      return vm
        .$dayjs(new Date(vm.animal.frigorifico.dtMorte))
        .diff(vm.animal.dataNascimento, "month");
    },
    getURLSelo() {
      const images = require.context(
        "./static/safebeef-levels",
        false,
        /\.png$/
      );

      if (
        !vm.animal.medicamentosAplicados ||
        !vm.animal.nutricoesAplicadas ||
        !vm.animal.pesoHistorico
      )
        return null;

      if (
        vm.animal.medicamentosAplicados.length >= 3 &&
        vm.animal.nutricoesAplicadas.length >= 1 &&
        vm.animal.pesoHistorico.length >= 3
      ) {
        return images("./" + "ouro" + ".png");
      } else if (
        vm.animal.medicamentosAplicados.length >= 2 &&
        vm.animal.nutricoesAplicadas.length >= 1 &&
        vm.animal.pesoHistorico.length >= 2
      ) {
        return images("./" + "prata" + ".png");
      } else if (
        vm.animal.medicamentosAplicados.length >= 1 &&
        vm.animal.nutricoesAplicadas.length >= 1 &&
        vm.animal.pesoHistorico.length >= 1
      ) {
        return images("./" + "bronze" + ".png");
      } else {
        return images("./" + "sem-classificacao" + ".png");
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.cow {
  width: 35px;
  height: auto;
}

.info {
  width: 20px;
  height: auto;
  cursor: pointer;
}

body {
  background-color: black;
}

#app {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

#principal {
  color: #ffffff;
}

#nav {
  margin: 10px;
  padding-bottom: 5px;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

#nav a {
  font-weight: bold;
  color: gray;
  display: inline-block;
}

#nav a.router-link-exact-active {
  border-radius: 25px;
  padding: 5px;
  color: #ffffff;
  background-color: #d20a11;
}

.accordion-color:not(.collapsed) {
  color: #000000 !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-color:focus {
  z-index: 3;
  border-color: #d20a11 !important;
  outline: 0;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  color: #ffffff;
  background-image: url("./assets/arrow.png") !important;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.plusCollapse {
  width: 20px;
  height: auto;
  cursor: pointer;
}

.hiddenRow {
  padding: 0 !important;
}
</style>
